<template>
  <v-row>
    <LoadingSpinner v-model="dataLoading"/>
    <v-col cols="9">
      <v-card class="white">
        <v-card-text>
          <div class="chat-container">
            <v-container class="fill-height">
              <v-row align="end" class="fill-height">
                <v-col>
                  <div class="chat-lists">
                    <div v-for="(item, index) in chat" :key="`message-${index}`">
                      <div
                        class="chat-msg"
                        :class="item.is_sender ? 'owner' : ''"
                      >
                        <div class="chat-msg-profile">
                          <v-avatar class="chat-msg-img" :color="item.is_sender ? 'indigo': 'red'" size="36">
                            <span class="white--text">{{ item.sender_name[0] }}</span>
                          </v-avatar>
                          <div class="chat-msg-date">
                            <span>{{ item.created_at | date }}</span>
                          </div>
                        </div>
                        <div class="chat-msg-content">
                          <div class="chat-msg-text"> {{ item.message }} </div>
                        </div>
                      </div>
                    </div>
                  </div>
<!--                  <div v-for='(item, index) in chat' :key='index'-->
<!--                       :class="['d-flex flex-row align-center my-2', item.is_sender ?  'justify-end': null]">-->
<!--                    <span v-if='item.is_sender' class='blue&#45;&#45;text mr-3'>{{ item.message }}</span>-->
<!--                    <v-avatar :color="item.is_sender ? 'indigo': 'red'" size='36'>-->
<!--                      <span class='white&#45;&#45;text'>{{ item.sender_name[0] }}</span>-->
<!--                    </v-avatar>-->
<!--                    <span v-if='!item.is_sender' class='blue&#45;&#45;text ml-3'>{{ item.message }}</span>-->
<!--                  </div>-->
                </v-col>
              </v-row>
            </v-container>
          </div>
          <v-container class="ma-0 pa-0">
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-text-field
                    v-model="msg"
                    :disabled="!isChatOpen"
                    placeholder="Type your message"
                    @keyup.enter="sendMessage"
                  />
                  <v-btn
                    :loading="messageSending"
                    class="ml-4"
                    color="secondary"
                    icon
                    :disabled="!isChatOpen"
                    @click="sendMessage"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-text>
          <v-btn
            class="mb-3"
            color="secondary"
            :disabled="!isChatOpen"
            :loading="resolvingTicket"
            @click="resolveTicket"
          >
            Mark as resolved
          </v-btn>
          <v-simple-table>
            <tbody>
            <tr>
              <th> Status</th>
              <td v-html="statusBadge(ticketData.status)"></td>
            </tr>
            <tr>
              <th> Opened</th>
              <td>{{ ticketData.created_at | date }}</td>
            </tr>

            <tr>
              <th> For</th>
              <td>{{ ticketData.subject }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-text class="mt-3">
          <v-simple-table>
            <tbody>
            <tr>
              <th> Associated</th>
              <td> {{ ticketData.associated_in }}</td>
            </tr>
            <tr>
              <th> Association</th>
              <td>
                <p> Voluptatem non consequuntur dolore sequi cupiditate delectus. </p>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import LoadingSpinner from '@/components/Utils/LoadingSpinner';
import { getMessages, resolveTicket, sendSupportMessage } from '@/api/user';
import Toast from '@/utils/Toast';

export default {
  name: 'SupportTicketDetails',
  components: { LoadingSpinner },

  data() {
    return {
      ticketId: null,
      dataLoading: true,
      resolvingTicket: false,
      msg: '',
      chat: [],
      ticketData: {},
      messageSending: false
    };
  },
  computed: {
    isChatOpen() {
      return this.ticketData?.status === 'open';
    }
  },
  methods: {
    async sendMessage() {

      if (!this.msg) return;

      if (!this.isChatOpen) return;

      this.messageSending = true;

      try {

        const message = await sendSupportMessage(this.ticketId, { message: this.msg });
        this.chat.push(message.data.data);
        this.clearInput();

      } catch (e) {

        this.messageSending = false;
        Toast.error(e.message || 'Something went wrong');

      }
      this.messageSending = false;
    },
    clearInput() {
      this.msg = '';
    },
    async resolveTicket() {

      this.resolvingTicket = true;

      const data = {
        status: 'resolved'
      };

      try {
        const ticket = await resolveTicket(this.ticketId, data);
        this.ticketData = ticket.data.data;
      } catch (e) {
        this.resolvingTicket = false;
        Toast.error(e.message || 'Something went wrong');
      }
      this.resolvingTicket = false;
    }
  },

  async mounted() {
    this.dataLoading = true;
    this.ticketId = this.$route.params.ticket;
    const message = await getMessages(this.ticketId);
    this.chat = message.data.data.messages;
    this.ticketData = message.data.data;
    this.dataLoading = false;
  }
};
</script>

<style lang='scss' scoped>
.chat-container {
  height: 100%;

  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column-reverse;
}

.scroll-start-at-top {
  flex: 1 1 0%;
}



.chat-lists {
  //min-height: 70vh;
  height: 100%;
  max-height: 400px;
  //max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
}

.scroll-start-at-top {
  flex: 1 1 0%;
}
.owner {
  flex-direction: row-reverse;
}

.chat-msg.owner {
  .chat-msg-profile{
    .chat-msg-date {
      left: auto;
      right: calc(100% + 12px) !important;
    }
  }
  .chat-msg-text {
    background-color: #0086ff;
    color: #ffffff;
    border-radius: 20px 20px 0 20px;
  }
  .chat-msg-content {
    margin-left: 0;
    margin-right: 12px;
  }
}
.chat-msg {
  display: flex;
  padding: 0 20px 45px;
  //align-items: flex-end;

  .chat-msg-profile {
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: -20px;
    position: relative;

    .chat-msg-img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    .chat-msg-date {
      position: absolute;
      left: calc(100% + 12px);
      bottom: 0;
      font-size: 12px;
      font-weight: 600;
      color: #c0c7d2;
      white-space: nowrap;
    }
  }

  .chat-msg-content {
    margin-left: 12px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .chat-msg-text {
    background-color: #f1f2f6;
    padding: 15px;
    border-radius: 20px 20px 20px 0;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
  }

}
</style>
